<template>
  <div>
    <!-- Controllers -->
    <div class="d-flex justify-end py-4 pr-6">
      <div class="mr-auto pl-6">
        <div class="d-flex flex-row align-center pl-14">
          <v-icon
            color="primary"
            size="16"
          >
            arrow_back
          </v-icon>
          <a @click="navigateBackRoute()">
            <span class="ml-1 font-weight-bold">{{ $t('activity.editActivity.navigateBack') }}</span><span>{{ navigateBackTarget }}</span>
          </a>
        </div>
      </div>
      <v-btn
        v-if="!isPublished && !isStreaming"
        color="primary" type="submit"
        class="mr-3" outlined dark
        @click.prevent="update({ publish: false })"
        v-text="$t('common.saveAsDraft')"
      />
      <v-btn
        color="primary"
        type="submit"
        dark
        depressed
        @click.prevent="publishModal"
        v-text="$t('common.publish')"
      />
    </div>

    <!-- Body -->
    <v-container class="px-6 pt-0 pb-6 bigScreenForm">
      <v-row>
        <v-col md="8" sm="12">
          <v-row>
            <v-col id="name" cols="11">
              <h3 class="field-title" v-text="$t('activity.editActivity.generalInformation')" />
              <v-text-field
                v-model="form.name"
                :error-messages="getFieldErrors('name')"
                :label="$t('common.name')"
                outlined
                dense
                hide-details="auto"
                @blur="$v.form.name.$touch()"
              />
            </v-col>
            <v-col id="type" cols="11">
              <v-select
                v-model="form.type"
                :error-messages="getFieldErrors('type')"
                :label="$t('common.type')"
                :items="typeItems"
                :disabled="!isNewActivity"
                outlined
                dense
                hide-details="auto"
                class="my-2"
                @blur="$v.form.type.$touch()"
              />
            </v-col>
            <template v-if="isStreaming">
              <v-col cols="11" sm="6">
                <v-checkbox
                  v-model="form.isNewStreaming"
                  :true-value="false" :false-value="true"
                  :label="$t('activity.editActivity.useExistingStreaming')"
                  class="my-2"
                  hide-details="auto"
                  @change="switchIsNewStreaming($event)"
                />
              </v-col>
              <v-col v-if="!isNewActivity || !form.isNewStreaming" id="streamingChannel" cols="11">
                <v-text-field
                  v-model="form.streamingChannel"
                  :error-messages="getFieldErrors('streamingChannel')"
                  :placeholder="$t('activity.editActivity.pasteStreamingId')"
                  :label="$t('activity.editActivity.streamingId')"
                  outlined
                  dense
                  hide-details="auto"
                  class="my-2"
                  :readonly="form.isNewStreaming"
                  @blur="$v.form.streamingChannel.$touch()"
                >
                  <template #append>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-icon color="primary" v-on="on" @click="copyStreamingChannelToClipboard">
                          content_copy
                        </v-icon>
                      </template>
                      {{ $t('activity.editActivity.copyStreamingId') }}
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
            </template>
            <v-row
              v-if="isVideo"
              align="center"
              justify="center"
              class="pa-3"
            >
              <v-col id="video" cols="11">
                <video-field
                  v-model="form.video"
                  :error-messages="getFieldErrors('video')"
                />
              <!-- <v-text-field
                v-model="form.video"
                :label="$t('activity.editActivity.videoUrl')"
                outlined
                dense
                @blur="$v.form.video.$touch()"
              /> -->
              </v-col>
              <v-col cols="1">
                <global-tooltip
                  :text="$t('project.editProject.tooltipVideoYoutube')"
                />
              </v-col>
            </v-row>
            <v-col cols="12">
              <v-row class="d-flex flex-row align-center">
                <v-col cols="5">
                  <v-text-field
                    v-model="form.timezone" :label="$t('common.timeZone')"
                    outlined
                    dense
                    disabled
                    hide-details
                    class="mt-2"
                    :error-messages="getFieldErrors('timezone')"
                    @blur="$v.form.date.$touch()"
                  />
                </v-col>
                <v-col cols="1" />
                <v-col id="aproxDurationInMinutes" cols="5">
                  <v-text-field
                    v-model.number="form.aproxDurationInMinutes"
                    :error-messages="getFieldErrors('aproxDurationInMinutes')"
                    :label="$t('activity.editActivity.aproxDurationInMinutes')"
                    type="number" min="1" step="1"
                    outlined
                    dense
                    hide-details="auto"
                    class="mt-2"
                    @blur="$v.form.aproxDurationInMinutes.$touch()"
                  />
                </v-col>
                <v-col cols="1" />
              </v-row>
            </v-col>
            <v-col v-if="isDatetimeRequired" id="datetime" cols="12">
              <datetime-field
                v-model="form.datetime"
                :timezone="project.timezone"
                :error-messages="getFieldErrors('datetime')"
                :initial-iso-date="initialIsoDate"
                outlined
                dense
                class="mb-2"
                @blur="$v.form.datetime.$touch()"
              />
            </v-col>
            <v-col v-if="hasCategoryFeatures" cols="11">
              <v-select
                v-model="form.categoryIds"
                :error-messages="getFieldErrors('categoryIds')"
                :label="$t('common.categories')"
                :items="categories"
                item-value="id"
                item-text="name"
                outlined
                dense
                multiple
                class="my-2"
                @blur="$v.form.categoryIds.$touch()"
              />
            </v-col>
            <v-col v-if="project.type === 'long-term'" cols="11">
              <v-autocomplete
                v-model="form.tags"
                :label="$t('common.tags')"
                :items="project.tags"
                :menu-props="{ closeOnContentClick: true }"
                hide-selected
                multiple
                dense
                deletable-chips
                :delimiters="[',']"
                small-chips
                outlined
              />
            </v-col>
            <v-col v-if="!isUrl" cols="11">
              <html-field
                v-model="form.description"
                :placeholder="$t('activity.editActivity.description')"
                :error-messages="getFieldErrors('description')"
                @blur="$v.form.description.$touch()"
              />
            </v-col>
            <v-col v-if="isUrl" cols="11">
              <v-text-field
                v-model="form.url"
                :error-messages="getFieldErrors('url')"
                :placeholder="$t('common.url')"
                :label="$t('common.url')"
                outlined
                dense
                class="my-2"
                hide-details="auto"
                @blur="$v.form.url.$touch()"
              >
                <template #append>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon color="primary" v-on="on" @click="copyActivityUrlToClipboard">
                        content_copy
                      </v-icon>
                    </template>
                    {{ $t('activity.editActivity.copyActivityTypeUrl') }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" class="mt-8">
              <EditActivityStreaming v-if="isPublished && isStreaming" :organization-id="organizationId" :project-id="projectId" :activity-id="activityId" />
              <v-row>
                <v-col id="banner">
                  <h3 class="field-title" v-text="$t('activity.editActivity.banner')" />
                  <image-field v-model="form.banner" :error-messages="getFieldErrors('banner')" suffix="_banner" />
                </v-col>
                <v-col v-if="!isUrl" cols="12" sm="12">
                  <h3 class="field-title" v-text="$t('activity.editActivity.attachments')" />
                  <files-field
                    v-model="form.attachments"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <confirm-save-dialog
      v-if="nextRoute && open"
      @close-dialog="open = false"
      @navigate="navigate"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, requiredIf, integer, between, url } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'
import saveDialogUtils from '@/utils/mixins/saveDialog'

export default {
  name: 'EditActivity',
  components: {
    HtmlField: () => import('@/components/formFields/HtmlField'),
    ImageField: () => import('@/components/formFields/ImageField'),
    VideoField: () => import('@/components/formFields/VideoField'),
    DatetimeField: () => import('@/components/formFields/DatetimeField'),
    FilesField: () => import('@/components/formFields/FilesField'),
    EditActivityStreaming: () => import('@/modules/activity/EditActivityStreaming'),
    ConfirmSaveDialog: () => import('@/components/ConfirmSaveDialog'),
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
  },
  mixins: [formUtils, saveDialogUtils],
  beforeRouteEnter(to, from, next) {
    next(
      component => component.previousRoute = from,
    )
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    activityId: { type: String, default: null },
  },
  data() {
    return {
      previousRoute: null,
      form: {},
      typeItems: [
        { text: this.$t('activity.type.streaming'), value: 'streaming' },
        { text: this.$t('activity.type.video'), value: 'video' },
        { text: this.$t('activity.type.image'), value: 'image' },
        { text: this.$t('common.url'), value: 'url' },
      ],
      initialIsoDate: this.$route.query.date?.split('-').length === 3 ? this.$route.query.date : null,
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ hasCategoryFeatures: 'project/hasCategoryFeatures' }),
    ...mapGetters({ categories: 'project/categories' }),
    ...mapGetters({ isShortTerm: 'project/isShortTerm' }),
    ...mapGetters({ isLongTerm: 'project/isLongTerm' }),
    ...mapGetters({ activity: 'activity/data' }),
    ...mapGetters({ streaming: 'streaming/data' }),
    isStreaming: ({ form }) => form.type === 'streaming',
    isVideo: ({ form }) => form.type === 'video',
    isNewActivity: ({ activityId }) => !activityId,
    isPublished: ({ activity }) => activity?.published ?? false,
    isDatetimeRequired: ({ isStreaming, project }) => isStreaming || project.type === 'short-term',
    isUrl: ({ form }) => form.type === 'url',
    navigateBackTarget({ isShortTerm, isLongTerm }) {
      return this.$t('project.sections.calendar')
    },
  },
  validations() {
    return {
      form: {
        name: { required },
        type: { required },
        streamingChannel: { required: requiredIf(() => this.isStreaming && (!this.isNewActivity || !this.form.isNewStreaming)) },
        video: { required: requiredIf(() => this.isVideo) },
        timezone: { required },
        aproxDurationInMinutes: { required, integer, between: between(1, 300) },
        datetime: { required: requiredIf(() => this.isDatetimeRequired) },
        categoryIds: {},
        description: {},
        url: { required: requiredIf(() => this.isUrl), url },
        banner: { required },
        attachments: {},
      },
    }
  },
  async created() {
    if (this.isNewActivity) {
      await this.$store.dispatch('activity/unbind')
      await this.$store.dispatch('streaming/unbind')
    }
    this.form = {
      name: this.activity?.name,
      type: this.activity?.type,
      streamingChannel: this.activity?.streamingChannel ?? null,
      video: this.activity?.video ?? null,
      timezone: this.project.timezone,
      aproxDurationInMinutes: this.activity?.aproxDurationInMinutes,
      datetime: this.isNewActivity || !this.activity.date ? null : new Date(this.activity.date.seconds * 1000),
      categoryIds: this.activity?.categoryIds ?? [],
      description: this.activity?.description ?? '',
      url: this.activity?.url ?? '',
      banner: this.activity?.banner,
      attachments: this.activity?.attachments ?? [],
      ...(this.project.type === 'long-term' && { tags: this.activity?.tags ?? [] }),
      isNewStreaming: this.isNewActivity ? true : (this.activity.streamingChannel === `${this.projectId}-${this.activityId}`),
    }
  },
  methods: {
    navigateBackRoute() {
      if (this.previousRoute.name) this.$router.go(-1)
      else this.$router.push({ name: 'project-calendar', params: { date: 'all' } })
    },
    switchIsNewStreaming(ev) {
      if (ev) this.form.streamingChannel = `${this.projectId}-${this.activityId}`
      else this.form.streamingChannel = null
    },
    copyStreamingChannelToClipboard() {
      navigator.clipboard.writeText(this.form.streamingChannel)
      this.$store.dispatch('alert/openAlertBox', ['alertSuccess', this.$t('common.copiedSuccess')])
    },
    copyActivityUrlToClipboard() {
      navigator.clipboard.writeText(this.form.url)
      this.$store.dispatch('alert/openAlertBox', ['alertSuccess', this.$t('common.copiedSuccess')])
    },
    parseForm() {
      const { form, isUrl, project } = this
      return (({ time, isNewStreaming, datetime, categoryIds, ...rest }) => ({
        ...rest,
        date: form.datetime,
        ...(categoryIds && ({ categoryIds })),
        ...(isUrl && ({ loginUrl: project.activitySettings?.loginUrl ?? null })),
      }))(form)
    },
    update({ publish }) {
      const { organizationId, projectId, form, parseForm, isNewActivity, isStreaming, streaming } = this
      if (!this.isFormValid()) {
        const [id, value] = Object
          .entries(this.$v.form.$model)
          .find(item => this.$v.form[item[0]].$invalid && !item[1])
        const missingInput = document.getElementById(id)
        const y = missingInput.getBoundingClientRect().top - 64
        window.scroll({ top: y, behavior: 'smooth' })
        return
      }
      this.runAsync(async () => {
        if (isNewActivity) {
          const activityId = await this.$store.dispatch('activity/create', { organizationId, projectId, data: parseForm(), publish })
          await this.$store.dispatch('activity/bind', { organizationId, projectId, activityId })
        } else {
          await this.$store.dispatch('activity/update', { organizationId, projectId, activity: this.activity, data: parseForm(), publish })
        }
        if (isStreaming && form.isNewStreaming && !streaming) {
          if (isNewActivity) {
            form.streamingChannel = `${projectId}-${this.activity.id}`
            await this.$store.dispatch('activity/update', { organizationId, projectId, activity: this.activity, data: parseForm(), publish })
          }
          await this.$store.dispatch('streaming/create', { organizationId, streamingId: form.streamingChannel })
        }
        this.userAgreedToLeave = true
        this.$router.go(-1)
      })
    },
    publishModal() {
      if (!this.isPublished && !this.project?.features?.doNotSendNotificationOnActivityPublish) {
        this.$confirm(this.$t('activity.editActivity.confirmPublish')).then(confirm => confirm && this.update({ publish: true }))
      } else {
        this.update({ publish: true })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .tiptap-vuetify-editor__content {
  max-height: 150px;
}

.field-title {
  margin-bottom: 16px;
}
</style>
